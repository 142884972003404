  
html {
    box-sizing: border-box;
    font-size: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;   
}

body {
    padding: 1.5rem;
    background-color: $background-color;
    color: $text-color;
    font-family: 'Roboto', sans-serif;
    line-height: 1.3;

    
}

main {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    padding: 1rem;
    border-radius: 15px;
    max-width: 1200px;
    margin: 0 auto;
    img {
        width: 100%;
    }
    h1 {
        text-align: center;
        width: 80%;
        color: $text-color;
    }
}

