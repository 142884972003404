/* usage : 

    @media VARIABLE_NAME {
        // style in here 
    }
*/
html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  padding: 1.5rem;
  background-color: #e6eff8;
  color: #5b5c5e;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
}

main {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  padding: 1rem;
  border-radius: 15px;
  max-width: 1200px;
  margin: 0 auto;
}
main img {
  width: 100%;
}
main h1 {
  text-align: center;
  width: 80%;
  color: #5b5c5e;
}

h1, h2, h3 {
  margin-top: 0px;
}

h1 {
  text-transform: uppercase;
  color: #000000;
}